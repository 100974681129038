import React from 'react'

import { Switch } from 'react-router-dom'
import Route from './Route'

import LandingPage from '../pages/LandingPage'
import Login from '../pages/Login'
import Review from '../pages/Review'
import Logout from '../pages/Logout'

function Routes() {
  return (
    <Switch>
      <Route exact path='/' isPrivate={false}>
        <LandingPage />
      </Route>
      <Route path='/login' isPrivate={false}>
        <Login />
      </Route>
      <Route path='/review/:id' isPrivate>
        <Review />
      </Route>
      <Route path='/logout' isPrivate={false}>
        <Logout />
      </Route>
      <Route path='*' isPrivate={false}>
        <LandingPage />
      </Route>
    </Switch>
  )
}

export default Routes
