import React, { useContext } from 'react'
import { Redirect } from 'react-router-dom'
import { AuthContext } from '../../contexts/AuthContext'

const Logout: React.FC = () => {
  const { destroySession } = useContext(AuthContext)

  destroySession()
  return <Redirect to='/' />
}
export default Logout
