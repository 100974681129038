import React from 'react'
import Lock from '../../auth/Lock'
import { BackgroundContainer } from './style'

const Login: React.FC = () => {
  return (
    <BackgroundContainer>
      <Lock />
    </BackgroundContainer>
  )
}
export default Login
