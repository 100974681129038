import React, { useRef, useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import QRCode from 'qrcode.react'
import { ModelViewer, ModelViewerElement } from '@r2u/react-ar-components'
import {
  Body,
  Fig,
  InfoBar,
  TryItBar,
  CarouselBar,
  Arrow,
  // ZoomBar,
  // IconZoomIn,
  // IconZoomOut,
  Icon3D,
  TextStyleBold,
  TextStyleLight,
  TextStyleSmall,
  HiddenAssetName
} from './styles'

import { ProductContext } from '../../contexts/ProductContext'
import { VersionContext } from '../../contexts/VersionContext'
import { Asset } from '../../services/api'

const isIOS = (): boolean => /iPhone|iPad|iPod/i.test(navigator.userAgent)
const isAndroid = (): boolean =>
  /Android|webOS|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)

const AssetViewer: React.FC = () => {
  const { t } = useTranslation(['review'])
  const { product, neighbours } = useContext(ProductContext)
  const { versionIndex, assetIndex, setAssetIndex, assets } = useContext(VersionContext)
  const [QRCodeVisible, setQRCodeVisible] = useState(false)
  const [AssetNameVisible, setAssetNameVisible] = useState(false)

  const toggleQRCodeVisibility = () => setQRCodeVisible(!QRCodeVisible)
  const toggleAssetNameVisibility = () => setAssetNameVisible(!AssetNameVisible)

  const ref = useRef<ModelViewerElement>(null)

  useEffect(() => {
    if (ref.current) ref.current.src = assets[assetIndex]?.url || ''
  }, [assets, assetIndex])

  const assetsList = assets.map((asset, listIndex) => (
    <Fig
      ListIndex={listIndex}
      AssetIndex={assetIndex}
      key={asset.id}
      onClick={() => {
        setAssetIndex(listIndex)
      }}
      onKeyDown={(ev) => {
        setAssetIndex(carouselKeyboardHandler(ev.key))
      }}
    />
  ))

  const carouselKeyboardHandler = (key: string) =>
    key === 'ArrowLeft'
      ? assetIndex === 0
        ? assets.length - 1
        : assetIndex - 1
      : key === 'ArrowRight'
      ? assetIndex === assets.length - 1
        ? 0
        : assetIndex + 1
      : assetIndex

  // TODO: think on how to properly display product not found
  const name = product ? product.name : ''
  const sku = product ? product.sku : t('notFound.sku')

  const glb = product?.Versions[versionIndex]?.Assets?.find((e: Asset) => e.type === 'GLB')?.url
  const glbURIEncoded = glb ? window.encodeURIComponent(glb) : null

  const usdz = product?.Versions[versionIndex]?.Assets?.find((e: Asset) => e.type === 'USDZ')?.url
  const usdzURIEncoded = usdz ? window.encodeURIComponent(usdz) : null

  const viewerUrl =
    glbURIEncoded && usdzURIEncoded
      ? `https://viewer.r2u.io/?glb=${glbURIEncoded}&usdz=${usdzURIEncoded}`
      : glbURIEncoded
      ? `https://viewer.r2u.io/?glb=${glbURIEncoded}`
      : usdzURIEncoded
      ? `https://viewer.r2u.io/?usdz=${usdzURIEncoded}`
      : null

  useEffect(() => {
    if (viewerUrl && (isIOS() || isAndroid())) window.location.href = viewerUrl
  }, [viewerUrl])

  return (
    // TODO: improve loading screen
    <Body QRCodeVisible={QRCodeVisible}>
      <div className='grid'>
        <div className='card'>{/* <p> Produtos a revisar: n </p> */}</div>
        <div className='card'>
          {neighbours?.prev ? (
            <Arrow>
              <Link to={`${neighbours.prev.id}`}>&#8249;</Link>
            </Arrow>
          ) : null}
        </div>
        <div className='card'>
          <InfoBar onClick={toggleAssetNameVisibility}>
            <TextStyleLight>
              <TextStyleBold> {name.split('', 45)} </TextStyleBold>
              SKU: {sku}
            </TextStyleLight>
          </InfoBar>
          <HiddenAssetName AssetNameVisible={AssetNameVisible}> {name} </HiddenAssetName>
        </div>
        <div className='card'>
          {neighbours?.next ? (
            <Arrow>
              <Link to={`${neighbours.next.id}`}>&#8250;</Link>
            </Arrow>
          ) : null}
        </div>
        <div className='card'>
          {assets[assetIndex] ? (
            assets[assetIndex]?.type === 'GLB' ? (
              <ModelViewer
                ref={ref}
                src={assets[assetIndex].url}
                shadowSoftness={1}
                exposure={0.8}
                autoRotate={false}
                environmentImage='neutral'
              />
            ) : (
              <img src={assets[assetIndex].url} alt='Asset Render' />
            )
          ) : (
            <p> {t('notFound.assetUnavailable')} </p>
          )}
        </div>
        <div className='card try'>
          {viewerUrl ? (
            <TryItBar onClick={toggleQRCodeVisibility}>
              <Icon3D />
              <TextStyleSmall> {t('ar.open')} </TextStyleSmall>
            </TryItBar>
          ) : (
            <TryItBar>
              <TextStyleSmall> {t('ar.unavailable')} </TextStyleSmall>
            </TryItBar>
          )}
        </div>
        {viewerUrl ? (
          <div className='card qrcode'>
            {!glbURIEncoded ? <span className='alert'>{t('ar.androidUnavailable')}</span> : null}
            {!usdzURIEncoded ? <span className='alert'>{t('ar.iOSUnavailable')}</span> : null}
            <QRCode value={viewerUrl} />
          </div>
        ) : null}
        {assets[assetIndex] && assets.length > 1 ? (
          <div className='card'>
            <CarouselBar>
              <span
                onClick={() => {
                  setAssetIndex(assetIndex === 0 ? assets.length - 1 : assetIndex - 1)
                }}
                onKeyDown={(ev) => {
                  setAssetIndex(carouselKeyboardHandler(ev.key))
                }}
                role='button'
                tabIndex={0}
              >
                {' '}
                &#8249;{' '}
              </span>
              <> {assetsList} </>
              <span
                onClick={() => {
                  setAssetIndex(assetIndex === assets.length - 1 ? 0 : assetIndex + 1)
                }}
                onKeyDown={(ev) => {
                  setAssetIndex(carouselKeyboardHandler(ev.key))
                }}
                role='button'
                tabIndex={0}
              >
                {' '}
                &#8250;{' '}
              </span>
            </CarouselBar>
          </div>
        ) : null}
        <div className='card'>
          {/*
            <ZoomBar>
              <IconZoomIn />
              <IconZoomOut />
            </ZoomBar>
              */}
        </div>
      </div>
    </Body>
  )
}

export default AssetViewer
