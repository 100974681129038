import React from 'react'
import { BrowserRouter as Router } from 'react-router-dom'

import './config/i18n'

import { AuthProvider } from './contexts/AuthContext'
import Routes from './routes'

import GlobalStyle from './styles/global'

function App() {
  return (
    <AuthProvider>
      <Router>
        <Routes />
        <GlobalStyle />
      </Router>
    </AuthProvider>
  )
}

export default App
