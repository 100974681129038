import React, { useEffect, useContext } from 'react'

import Auth0Lock from 'auth0-lock'
import { useLocation, useHistory } from 'react-router-dom'
import config from '../config'
import { AuthContext } from '../contexts/AuthContext'
import R2UConsole from '../assets/icons/R2U-console.png'

const Lock: React.FC = () => {
  const { createSession } = useContext(AuthContext)
  const history = useHistory()
  const locationHash = useLocation().hash

  const clientID = config.auth.AUTH0_CLIENT_ID

  const options = {
    auth: {
      responseType: 'token',
      sso: false,
      params: {
        scope: 'openid email profile'
      },
      audience: config.auth.AUTH0_AUDIENCE
    },
    theme: {
      logo: R2UConsole,
      primaryColor: '#5596d3'
    },
    language: 'en',
    languageDictionary: {
      title: 'Welcome'
    },
    container: config.auth.AUTH0_CONTAINER,
    allowedConnections: ['Username-Password-Authentication', 'google-oauth2'],
    allowSignUp: true,
    allowLogin: true,
    hashCleanup: false
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const lock = new Auth0Lock(clientID, config.auth.AUTH0_DOMAIN, options)
  const from = localStorage.getItem('from') || '/'

  useEffect(() => {
    if (!/access_token|id_token|error/.test(locationHash)) {
      lock.show()
    }
  }, [locationHash, lock])

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  lock.on('authenticated', async (authResult: { expiresIn?: any; accessToken: string }) => {
    const expiresAt = JSON.stringify(authResult.expiresIn * 1000 + new Date().getTime())

    localStorage.setItem('access_token', authResult.accessToken)
    localStorage.setItem('expires_at', expiresAt)
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    lock.getProfile(authResult.accessToken, (err, profile: { sub: any }) => {
      const { accessToken } = authResult
      const { sub: auth0Id } = profile
      localStorage.setItem('auth0_id', auth0Id)
      createSession(accessToken, () => {
        localStorage.removeItem('from')
        history.replace(from)
      })
    })
  })

  return (
    <div>
      <div id={config.auth.AUTH0_CONTAINER} />
    </div>
  )
}
export default Lock
