import React, { ReactElement, useContext } from 'react'
import { Route, Redirect, useHistory } from 'react-router-dom'
import { AuthContext } from '../contexts/AuthContext'

interface Props {
  children: ReactElement
  isPrivate?: boolean
  exact?: boolean
  path: string
}

const RouteWrapper: React.FC<Props> = ({
  path,
  children,
  exact = false,
  isPrivate = true
}: Props) => {
  const { isSigned } = useContext(AuthContext)
  const history = useHistory()

  if (!isSigned && isPrivate) {
    // TODO: Ideally, 'from' should be a state parameter stored as a nonce, to avoid CSRF attacks
    // https://auth0.com/docs/protocols/state-parameters#csrf-attacks
    localStorage.setItem('from', history.location.pathname)
    return <Redirect to='/login' />
  }

  return (
    <Route path={path} exact={exact}>
      {children}
    </Route>
  )
}

export default RouteWrapper
