import React, { createContext, ReactNode, useState, useCallback, useEffect } from 'react'
import Auth0Lock from 'auth0-lock'

import config from '../config'

interface Props {
  children: ReactNode
}

interface AuthContextData {
  isSigned: boolean
  createSession: (accessToken: string, cb?: () => void) => void
  destroySession: () => void
}

export const AuthContext = createContext<AuthContextData>({} as AuthContextData)

export const AuthProvider: React.FC<Props> = ({ children }: Props) => {
  const [isSigned, setSigned] = useState(false)

  const storedToken = localStorage.getItem('access_token') || ''
  const expiresAt = localStorage.getItem('expires_at') || ''

  const destroySession = () => {
    localStorage.removeItem('auth0_id')
    localStorage.removeItem('access_token')
    localStorage.removeItem('expires_at')
    setSigned(false)
  }

  const createSession = useCallback((accessToken: string, redirectCallback?: () => void) => {
    const clientID = config.auth.AUTH0_CLIENT_ID
    const domain = config.auth.AUTH0_DOMAIN
    const lock = new Auth0Lock(clientID, domain)

    lock.getProfile(accessToken, (err, profile) => {
      if (profile) {
        setSigned(true)
        if (redirectCallback) redirectCallback()
      } else destroySession()
    })
  }, [])

  useEffect(() => {
    const date = Date.now()
    if (Number(expiresAt) > date) createSession(storedToken)
    else destroySession()
  }, [storedToken, createSession, expiresAt])

  return (
    <AuthContext.Provider
      value={{
        isSigned,
        createSession,
        destroySession
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}
