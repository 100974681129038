const stage = process.env.REACT_APP_STAGE

const api = {
  baseURL:
    stage === 'production'
      ? 'https://api.3d.r2u.io'
      : stage === 'dev'
      ? 'https://dev.api.3d.r2u.io'
      : 'http://localhost:4000'
}

const auth =
  stage === 'production'
    ? {
        AUTH0_DOMAIN: 'real2u.auth0.com',
        AUTH0_CLIENT_ID: '9Vq6uwQBECDU8qTHZVoJD5RN53nmncCK',
        AUTH0_CALLBACK_URL: 'https://3d.r2u.io',
        AUTH0_AUDIENCE: 'https://api.3d.r2u.io',
        AUTH0_CONTAINER: 'auth0-login-container'
      }
    : stage === 'dev'
    ? {
        AUTH0_DOMAIN: 'real2u.auth0.com',
        AUTH0_CLIENT_ID: 'A7iDz2uj8RXcwwsGupqa1soZcoQ7cDv9',
        AUTH0_CALLBACK_URL: 'https://dev.3d.r2u.io',
        AUTH0_AUDIENCE: 'https://dev.api.3d.r2u.io',
        AUTH0_CONTAINER: 'auth0-login-container'
      }
    : {
        AUTH0_DOMAIN: 'real2u.auth0.com',
        AUTH0_CLIENT_ID: 'A7iDz2uj8RXcwwsGupqa1soZcoQ7cDv9',
        AUTH0_CALLBACK_URL: 'http://localhost:3000',
        AUTH0_AUDIENCE: 'https://dev.api.3d.r2u.io',
        AUTH0_CONTAINER: 'auth0-login-container'
      }

export default {
  api,
  auth
}
