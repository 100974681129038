import styled from 'styled-components'

import background from '../../assets/background/console-bg.png'

export const BackgroundContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
  background: url(${background}) no-repeat center;
  background-size: cover;

  &::before {
    content: '';
    position: absolute;
    background-image: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0),
      rgba(214, 214, 214, 0),
      rgba(53, 67, 77, 0.69) 44%,
      rgba(31, 47, 59, 0.79)
    );
    top: 70px;
    bottom: 0;
    left: 0;
    right: 0;
  }
`
